@media only screen and (min-width: 1250px) {
  body {
    margin-left: 6%;
    margin-right: 5%;
    background-color: #f5f7fb;
  }
}

@media only screen and (max-width: 768px) {
  body {
    margin-left: 0%;
    margin-right: 0%;
    background-color: #f5f7fb;
  }
}
