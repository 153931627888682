.search-container {
  float: right;
  position: relative;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #888;
}

.navbar-nav .nav-item .nav-link {
  color: black; /* Set the default text color to black */
  transition: color 0.3s ease; /* Add a transition effect for smooth color change on hover */
}

/* Navbar link hover style */
.navbar-nav .nav-item .nav-link:hover {
  color: #66cc99; /* Set the text color to light green on hover */
}

.navbar-nav .nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.search-results-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  /* max-height: 300px; */
  overflow-y: auto;
  white-space: nowrap;
  /* width: max-content; */
  font-size: 10px;
  width: 100%;
}

.search-results-dropdown .dropdown-item {
  display: block;
  font-size: 11px;
  padding: 5px;
  cursor: pointer;
  text-decoration: none;
  /* color: #000; */
}

.search-results-dropdown .dropdown-item:hover {
  background-color: #f5f5f5;
}

@media (max-width: 768px) {
  .navbar .navbar-toggler {
    position: absolute;
    right: 0;
    top: 0px;
    padding: 1%;
    margin-right: 2%;
    /* background-color: rgb(222, 224, 230); */
  }

  .form-control {
    width: 100%;
  }

  .search-results-dropdown {
    width: 100%;
  }
}
