.load-more-button {
  background-color: #eaedf2;
  color: #171616;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: small;
  font-weight: bold;
  border-radius: 5%;
}

.load-more-button:hover {
  background-color: #dbe4ee;
}

.exchangeMarket tbody tr {
  transition: background-color 0.3s ease;
}

.exchangeMarket tbody tr:hover {
  background-color: #e2f9f3;
  cursor: pointer;
}
