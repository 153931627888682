.footer .topbar-nav .nav-item:hover {
  color: #66cc99;
}

.footer .navbar-nav .nav-item .nav-link {
  margin: 0;
  padding: 0;
}

/* In your footer.css file */
.social-links-grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 5px;
}
