.progress-bar-container {
    width: 100%;
    height: 8px;
    background-color: #E9EFFF;
    position: relative;
    margin-bottom: 10px;
  }
  
  .progress-bar {
    height: 100%;
     border-radius: 8px;
    position: relative;
  }
  
  /* .triangle {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #A6B0C3;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  } */

  .triangle {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid #A6B0C3;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .container {
    margin-top: 20px;
    text-align: center;
  }
  
  .progress-labels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  