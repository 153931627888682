.table-hover:hover td,
.table-hover:hover th {
  color: rgb(65, 152, 229);
}

.rdt_TableCell:hover,
.rdt_TableRow:hover {
  color: rgb(65, 152, 229);
  width: 100%;
}

.rdt_TableCell .rdt_TableRow {
  width: 100%;
}


/* .rdt_TableRow:hover {
    background-color: rgb(1, 8, 4);
   
  } */

/* .table-hover:hover {
    background-color: rgb(1, 8, 4);
   
  } */
