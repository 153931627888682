.sorting-header {
  position: relative;
  cursor: pointer;
}

.sorting-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.sorting-header:hover .sorting-icon {
  opacity: 1;
}
