.dpxigv {
  /* position: relative; */
  display: flex;
  /* -webkit-box-align: center; */
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  word-break: break-word;
}

.gSVZoG {
  -webkit-box-flex: 1;
  max-width: 100%;
  min-width: 100px;
}

.hAPPhC {
  margin-left: 0px;
  padding: 0px;
}

.cKBiPJ:not(:last-of-type) {
  border-bottom: 50px solid rgba(9, 58, 235, 0.12);
}

.cOhUY {
  background-color: #f5f7fb;
}

.cKBiPJ {
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-size: 11px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(255, 255, 255);
  min-height: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px !important;
  height: 25px !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #54AFEB;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
