.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 10px;
  z-index: 1;
  width: auto;
  white-space: nowrap; /* Prevents the content from wrapping */
}

.dropdown-content .search-results-dropdown {
  display: block;
  cursor: pointer;
  text-decoration: none;
}

.dropdown:hover .dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding: 2px 5px;
  line-height: 20px;
  font-size: small;
}

@media (max-width: 767px) {
  .dropdown {
    position: static;
    display: block;
  }

  .dropdown-content {
    position: static;
    background-color: #f9f9f9;
    box-shadow: none;
    padding: 12px 16px;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
}
