.topnav {
  overflow: hidden;
  background-color: #ffffff;
}

.topnav a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  font-size: 12px;
}

.topnav input[type="text"] {
  float: right;
  padding: 12px;
  margin-top: 7px;
  margin-bottom: 5px;
  margin-right: 5px;
  border: none;
  font-size: 12px;
  width: 180px;
  height: 30px;
  background-color: #f3f9ff;
  border-radius: 5px;
  position: relative;
}

.topnav .nav-link:hover {
  color: #66cc99;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #888;
  pointer-events: none;
}

.search-bar {
  padding-right: 30px;
}

@media screen and (max-width: 600px) {
  .topnav a,
  .topnav input[type="text"] {
    text-align: left;
    margin: 0;
    padding: 10px;
  }

  .topnav input[type="text"] {
    border: 1px solid #ccc;
    margin-top: 5px;
    margin-right: 5px;
    width: 150px;
    height: 25px;
  }
}
