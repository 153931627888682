/* TradingData.css */

/* Styling for the entire trading table */
.trading-table {
  width: 100%;
}

/* Remove borders from the table */
.trading-table.table {
  border: none;
}

/* Add spacing between table cells vertically */
.trading-table tbody tr td {
  padding: 1.5%;
  color: rgb(2, 2, 26);
  font-size: 11px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

/* Styling for the table rows on hover */
.trading-table tbody tr:hover td {
  transform: translateY(-5px); /* Move the row up by 5 pixels on hover */
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2); /* Add a subtle box-shadow on hover */
  cursor: pointer;
}

/* Adding subtle animation on hover */
.trading-table tbody tr:hover td {
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.trading-col {
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Columns appear upward on hover */
.trading-col:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  z-index: 1;
  /* Gradient background on hover */
  background: linear-gradient(#c5f2ef, #ffffff);
}

/* Styling for the coin icon */
.coin-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

/* Styling for the "Nothing to see for now!" message */
.no-data {
  text-align: center;
  padding: 10px;
  color: #808a9d;
  font-size: 1.1rem;
}
